<template>
  <div class="help">
    <div class="helpTitle">
      帮助
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <div class="helpCon">
      <div class="helpMsg">
        <div class="helpMsg-title">填写反馈信息</div>
        <nut-textarea autosize rows="3" v-model="value" />
      </div>
      <div class="helpImg">
        <div class="helpImg-title">请上传反馈图片</div>
        <nut-uploader
          multiple
          v-model:file-list="defaultFileList"
          :url="uploadUrl"
          name="image"
          @delete="deleteImg"
          @success="uploadSuc"
          :maximum="5"
        ></nut-uploader>
      </div>
    </div>
    <div class="helpSubmit" @click="submitData()">提交</div>
  </div>
</template>
<script>
import { ref } from "vue";
import { Toast } from "@nutui/nutui";

import { postHelp } from "../api/person.js";
export default {
  setup() {
    const uploadUrl = "https://www.autostay.cn/api/upload/fileUpload";
    const defaultFileList = ref([]);
    return {
      uploadUrl,
      defaultFileList,
    };
  },
  data() {
    return {
      value: "",
      imageUrl: [],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    uploadSuc(text) {
      this.imageUrl = this.imageUrl.concat(
        JSON.parse(text.responseText).data.path
      );
    },
    deleteImg(text) {
      this.imageUrl.splice(text.index, 1);
    },
    submitData() {
      let params = {
        remark: this.value,
        images: this.imageUrl,
      };
      postHelp(params).then((res) => {
        Toast.text(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      });
    },
  },
};
</script>
<style>
.help .nut-uploader__upload {
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
.help {
  .helpTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .helpCon {
    padding: 20px 15px;
    .helpMsg {
      .helpMsg-title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 7px;
      }
    }
    .helpImg {
      margin-top: 20px;
      .helpImg-title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
  }
  .helpSubmit {
    width: 345px;
    height: 45px;
    background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
    border-radius: 25px;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    line-height: 45px;
    margin: 0 auto;
    margin-top: 60px;
  }
}
</style>